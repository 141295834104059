// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content form .user-details-fgp {
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

input[type="password"] {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/ResetPassword.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".content form .user-details-fgp {\n  display: block;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 20px 0 12px 0;\n}\n\ninput[type=\"password\"] {\n  width: 100%;\n  margin-bottom: 20px;\n  padding: 12px;\n  border: 1px solid #ccc;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
