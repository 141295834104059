// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/PaymentSuccessPage.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["._success {\n    box-shadow: 0 15px 25px #00000019;\n    padding: 45px;\n    width: 100%;\n    text-align: center;\n    margin: 40px auto;\n    border-bottom: solid 4px #28a745;\n}\n\n._success i {\n    font-size: 55px;\n    color: #28a745;\n}\n\n._success h2 {\n    margin-bottom: 12px;\n    font-size: 40px;\n    font-weight: 500;\n    line-height: 1.2;\n    margin-top: 10px;\n}\n\n._success p {\n    margin-bottom: 0px;\n    font-size: 18px;\n    color: #495057;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
