// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px; 
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--cta {
  background-color: #242424;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn--medium {
 /* padding: 8px 20x; */
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,  .btn--large:hover {
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

.btn--primary:hover {
  background-color: transparent;
  color: #fff;
}

.btn--cta:hover {
  background-color: #fff;
  color: #242424;
  border: 1px solid #242424;
}











`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,iBAAiB;EACjB,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;CACC,sBAAsB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":[":root {\n  --primary: #fff;\n}\n\n.btn {\n  padding: 8px 20px;\n  border-radius: 2px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\n.btn--primary {\n  background-color: var(--primary);\n  color: #242424;\n  border: 1px solid var(--primary);\n}\n\n.btn--outline {\n  background-color: transparent;\n  color: #fff;\n  padding: 8px 20px; \n  border: 1px solid var(--primary);\n  transition: all 0.3s ease-out;\n}\n\n.btn--cta {\n  background-color: #242424;\n  color: var(--primary);\n  border: 1px solid var(--primary);\n}\n\n.btn--medium {\n /* padding: 8px 20x; */\n  font-size: 20px;\n}\n\n.btn--large {\n  padding: 12px 26px;\n  font-size: 20px;\n}\n\n.btn--medium:hover,  .btn--large:hover {\n  background: #fff;\n  color: #242424;\n  transition: all 0.3s ease-out;\n}\n\n.btn--primary:hover {\n  background-color: transparent;\n  color: #fff;\n}\n\n.btn--cta:hover {\n  background-color: #fff;\n  color: #242424;\n  border: 1px solid #242424;\n}\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
