// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-it-works-section {
  background-color: #fff; 
  padding: 20px;
  margin: auto;
  width: 100%; 
  max-width: 1200px;
  height: 50vh;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.how-it-works-section h2 {
  color: #2C3E50; 
  text-align: center;
  font-size: 28px;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
  gap: 20px;
  margin-top: 20px;
}

.step h3 {
  color: #E67E22;
  margin-bottom: 10px;
  font-size: 20px;
}

.step p {
  color: #666;
  line-height: 1.6;
  font-size: 16px;
}

@media (max-width: 768px) {
  .how-it-works-section {
    height: 120vh !important;
    background-color: #fff; 
    padding: 20px;
    margin: auto;
    width: 100%;
    height: 50vh;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .how-it-works-section h2 {
      font-size: 22px; 
  }

  .step h3 {
      font-size: 18px; /* Smaller step title on mobile */
  }

  .step p {
      font-size: 14px; /* Smaller text for better readability */
  }

  .steps-container {
      grid-template-columns: 1fr; /* Single column on mobile for better readability */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/HowItWorks.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB;IACxB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;MACI,eAAe;EACnB;;EAEA;MACI,eAAe,EAAE,iCAAiC;EACtD;;EAEA;MACI,eAAe,EAAE,wCAAwC;EAC7D;;EAEA;MACI,0BAA0B,EAAE,mDAAmD;EACnF;AACF","sourcesContent":[".how-it-works-section {\n  background-color: #fff; \n  padding: 20px;\n  margin: auto;\n  width: 100%; \n  max-width: 1200px;\n  height: 50vh;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n}\n\n.how-it-works-section h2 {\n  color: #2C3E50; \n  text-align: center;\n  font-size: 28px;\n}\n\n.steps-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); \n  gap: 20px;\n  margin-top: 20px;\n}\n\n.step h3 {\n  color: #E67E22;\n  margin-bottom: 10px;\n  font-size: 20px;\n}\n\n.step p {\n  color: #666;\n  line-height: 1.6;\n  font-size: 16px;\n}\n\n@media (max-width: 768px) {\n  .how-it-works-section {\n    height: 120vh !important;\n    background-color: #fff; \n    padding: 20px;\n    margin: auto;\n    width: 100%;\n    height: 50vh;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n  }\n\n  .how-it-works-section h2 {\n      font-size: 22px; \n  }\n\n  .step h3 {\n      font-size: 18px; /* Smaller step title on mobile */\n  }\n\n  .step p {\n      font-size: 14px; /* Smaller text for better readability */\n  }\n\n  .steps-container {\n      grid-template-columns: 1fr; /* Single column on mobile for better readability */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
