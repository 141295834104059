// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background */
    color: #333; /* Standard text color */
    font-family: 'Arial', sans-serif;
  }
  
  .privacy-policy-header {
    font-size: 1.5rem;
    color: rgb(26, 23, 23);

  }
  
  .privacy-text {
    line-height: 1.6;
    color: #333;
  }
  
  .links {
    color: #FF7A00; /* Link color matching logo */
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .container-list {
    background-color: #f2f2f2;
    padding: 22px 90px 25px;
    border: 1px solid lightgrey;
    border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TermOfServices.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB,EAAE,qBAAqB;IAChD,WAAW,EAAE,wBAAwB;IACrC,gCAAgC;EAClC;;EAEA;IACE,iBAAiB;IACjB,sBAAsB;;EAExB;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,cAAc,EAAE,6BAA6B;IAC7C,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,yBAAyB;IACzB,uBAAuB;IACvB,2BAA2B;IAC3B,kBAAkB;AACtB","sourcesContent":["/* PrivacyPolicy.css */\n.privacy-policy-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9; /* Light background */\n    color: #333; /* Standard text color */\n    font-family: 'Arial', sans-serif;\n  }\n  \n  .privacy-policy-header {\n    font-size: 1.5rem;\n    color: rgb(26, 23, 23);\n\n  }\n  \n  .privacy-text {\n    line-height: 1.6;\n    color: #333;\n  }\n  \n  .links {\n    color: #FF7A00; /* Link color matching logo */\n    text-decoration: none;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }\n\n  .container-list {\n    background-color: #f2f2f2;\n    padding: 22px 90px 25px;\n    border: 1px solid lightgrey;\n    border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
