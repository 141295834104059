// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content form .user-details-fgp {
    display: block;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,8BAA8B;IAC9B,qBAAqB;AACzB","sourcesContent":[".content form .user-details-fgp {\n    display: block;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin: 20px 0 12px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
