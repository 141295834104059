// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._failed {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px red !important;
}

._failed i {
  color: red !important;
  font-size: 55px;
}

._failed h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._failed {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/PaymentErrorPage.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,uCAAuC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["._failed {\n    box-shadow: 0 15px 25px #00000019;\n    padding: 45px;\n    width: 100%;\n    text-align: center;\n    margin: 40px auto;\n    border-bottom: solid 4px red !important;\n}\n\n._failed i {\n  color: red !important;\n  font-size: 55px;\n}\n\n._failed h2 {\n    margin-bottom: 12px;\n    font-size: 40px;\n    font-weight: 500;\n    line-height: 1.2;\n    margin-top: 10px;\n}\n\n._failed {\n    margin-bottom: 0px;\n    font-size: 18px;\n    color: #495057;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
